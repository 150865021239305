export default {
  booking: {
    numberOfPeople: "¿Cuántas personas seréis?",
    selectDay: "¿Qué día quieres venir?",
    selectHour: "¿A qué hora?",
    selectDayWithAvailability: "Selecciona un día con disponibilidad",
    zone: "¿En qué zona quieres comer?",
    selectHourMsg: "Selecciona una hora",
    bookingData: "Datos para la reserva",
    yourName: "Nombre completo",
    email: "Email",
    phone: "Teléfono",
    additionalComments: "Comentarios adicionales",
    placeBooking: "Reservar",
    commercialAcceptance:
      "Quiero recibir ofertas especiales y notificaciones del restaurante por correo electrónico y mensajes de texto",
    disclaimer:
      "Al hacer clic en «Reservar», aceptas las Condiciones legales del restaurante. Sus datos serán incorporados a un archivo del que es responsable OnClick Solucions S.L. - C/Manresa, 14, 08500 Vic, para gestionar su reserva y remitirle información comercial sobre sus productos y servicios. Si desea recibir información comercial por medios electrónicos del restaurante, marque la opción correspondiente. Los usuarios cuyos datos sean objeto de tratamiento podrán ejercitar gratuitamente los derechos de acceso e información, rectificación, cancelación y oposición de sus datos en los términos especificados en la Ley Orgánica 15/1999 de Protección de Datos de Carácter Personal , de acuerdo con el procedimiento legalmente establecido. Estos derechos podrán ser ejercitados dirigiendo comunicación por escrito, debidamente firmada, acompañada de fotocopia del DNI, a la dirección arriba mencionada oa través de la dirección de correo electrónico: info(a)onclick.cat",
    confirmationTitle: "Gracias por tu reserva",
    confirmationDesc:
      "Tu reserva ha sido confirmada correctamente. Te hemos enviado un email con los detalles.",
    fillAllFieldsError: "Rellena todos los campos para continuar",
    largeBookingInfo:
      "Para reservas superiores a {pax} personas, llámanos al {phone}",
  },
  defaults: {
    ok: "Vale",
  },
};
