import { createApp } from "vue";
import App from "./App.vue";

import { IonicVue } from "@ionic/vue";

/* Core CSS required for Ionic components to work properly */
import "@ionic/vue/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/vue/css/normalize.css";
import "@ionic/vue/css/structure.css";
import "@ionic/vue/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/vue/css/padding.css";
import "@ionic/vue/css/float-elements.css";
import "@ionic/vue/css/text-alignment.css";
import "@ionic/vue/css/text-transformation.css";
import "@ionic/vue/css/flex-utils.css";
import "@ionic/vue/css/display.css";
import VCalendar from "v-calendar";
import "v-calendar/dist/style.css";
import "./registerServiceWorker";
import i18n from "./i18n/i18n";
import router from "./router";

/* Theme variables */
import "./theme/variables.css";

import axios from "axios";
axios.defaults.baseURL = process.env.VUE_APP_API_URL;

// window.i18n = i18n;

const app = createApp(App).use(IonicVue).use(i18n).use(router);

app.use(VCalendar, {});

router.isReady().then(() => {
  app.mount("#app");
});
