import { createI18n } from "vue-i18n";
import messages from "./messages";

const i18n = createI18n({
  locale: localStorage.getItem("locale") ?? "ca", // set locale
  fallbackLocale: "ca", // set fallback locale
  messages, // set locale messages
});

export default i18n;
