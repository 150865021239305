import { createRouter, createWebHistory } from "@ionic/vue-router";
import { RouteRecordRaw } from "vue-router";
import HomePage from "../views/HomePage.vue";
import i18n from "../i18n/i18n";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    redirect: "/404",
  },
  {
    path: "/:lang(ca|es)/:restaurantSlug",
    name: "Home",
    component: HomePage,
  },
  {
    path: "/404",
    name: "404",
    component: {
      template: "<p>Page Not Found</p>",
    },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeResolve((to) => {
  if (to.params.lang) {
    const locale = to.params.lang;
    // @ts-ignore
    i18n.global.locale = locale;
    // @ts-ignore
    document.querySelector("html").setAttribute("lang", locale);
  }
});

export default router;
