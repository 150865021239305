export default {
  booking: {
    numberOfPeople: "Quantes persones sereu?",
    selectDay: "Quin dia vols venir?",
    selectHour: "A quina hora?",
    selectDayWithAvailability: "Selecciona un dia amb disponabilitat",
    zone: "A quina zona vols menjar?",
    selectHourMsg: "Selecciona una hora",
    bookingData: "Dades per la reserva",
    yourName: "Nom complet",
    email: "Email",
    phone: "Telèfon",
    additionalComments: "Comentaris addicionals",
    placeBooking: "Reservar",
    commercialAcceptance:
      "Vull rebre ofertes especials i notificacions del restaurant per correu electrònic i missatges de text",
    disclaimer:
      "En fer clic a «Reservar», acceptes les Condicions legals del restaurant. Les vostres dades seran incorporades a un fitxer del qual és responsable OnClick Solucions S.L. - C/Manresa, 14, 08500 Vic, per tal de gestionar la vostra reserva i remetre-li informació comercial sobre els seus productes i serveis. Si voleu rebre informació comercial per mitjans electrònics del restaurant, marqueu l'opció corresponent. Els usuaris les dades dels quals siguin objecte de tractament podran exercir gratuïtament els drets d'accés i informació, rectificació, cancel·lació i oposició de les seves dades en els termes especificats a la Llei Orgànica 15/1999 de Protecció de Dades de Caràcter Personal, d'acord amb el procediment legalment establert. Aquests drets podran ser exercitats dirigint comunicació per escrit, degudament signada, acompanyada de fotocòpia del DNI, a l'adreça esmentada més amunt oa través de l'adreça de correu electrònic: info(a)onclick.cat",
    confirmationTitle: "Gràcies per la teva reserva",
    confirmationDesc:
      "La teva reserva ha estat confirmada correctament. T'hem enviat un email amb els detalls.",
    fillAllFieldsError: "Omple tots els camps per continuar",
    largeBookingInfo: "Per reserves superiors a {pax} persones, truca'ns al {phone}",
  },
  defaults: {
    ok: "D'acord",
  },
};
